<template>
  <div class="text-center">
    <transition
      name="grid"
      mode="out-in">
      <ErrorCard
        v-if="error"
        :error="error" />
      <div
        v-else
        class="card py-5">
        <div class="card-body">
          <transition
            mode="out-in"
            name="fade">
            <div>
              <h3 v-skeleton="20" />
              <p v-skeleton="30" />
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  DOMAIN_FLESPAKKET,
  DOMAIN_MYPARCEL,
  DOMAIN_PORTAL_FLESPAKKET,
  DOMAIN_PORTAL_MYPARCEL,
  DOMAIN_PORTAL_SENDMYPARCEL,
  DOMAIN_SENDMYPARCEL,
} from '@/helpers/platform/data';
import { environment, subdomain } from '@/helpers/platform';
import { ApiError } from '@/services/ApiError';
import ErrorCard from '@/views/ErrorCard';
import MyParcelApi from '@/services/MyParcelApi';
import SkeletonLoader from '@/components/SkeletonLoader';
import { isDev } from '@/helpers/environment';
import { redirect } from '@/router/redirect';

const TIMEOUT_INCREMENT_RATE = 1.5;
const TIMEOUT_INITIAL_LENGTH = 50;
const TIMEOUT_MAX_LENGTH = 30000;

export default {
  name: 'PaymentStatus',
  components: { SkeletonLoader, ErrorCard },
  props: {
    type: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      error: null,
      timeOut: 50,
    };
  },

  created() {
    this.init();
  },

  methods: {
    /**
     * @returns {Promise}
     */
    async init() {
      try {
       const paymentStatus = this.type === 'canceled'
          ? await this.fetchPaymentStatus()
          : await this.fetchSuccessfulPaymentStatus();

        const redirectUrl = this.getReturnUrl(paymentStatus);

        if (!this.validateUrl(redirectUrl)) {
          this.error = new ApiError('Hash is not valid');
          return;
        }

        redirect(null, redirectUrl);
      } catch (e) {
        this.error = new ApiError(e);
      }
    },

    /**
     * Fetches the payment status for the hash given in the url.
     *
     * @returns {Promise<ApiError|Object>}
     */
    async fetchPaymentStatus() {
      const paymentStatus = await MyParcelApi.getPaymentStatus();
      const { status } = paymentStatus;

      switch (status) {
        case 'expired':
          return Promise.reject('Hash is expired');
        case 'invalid_hash':
          return Promise.reject('Hash is not valid');
      }
      return paymentStatus;
    },

    /**
     * Fetches the payment status for the hash given in the url. Expects the payment to be successful and retries until
     * the callback from the payment provider has been received.
     *
     * @returns {Promise<ApiError|Object>}
     */
    async fetchSuccessfulPaymentStatus() {
      let timeout = process.env.NODE_ENV === 'test' ? 0 : TIMEOUT_INITIAL_LENGTH;

      let paymentStatus;
      do {
        paymentStatus = await this.fetchPaymentStatus();

        if (paymentStatus.status === 'initialized') {
          // Timeout length increments from initial value to max value based on the increment rate.
          timeout = this.increaseTimeout(timeout);
          await this.wait(timeout);
        }

        // If payment status is initialized, keep retrying the call until the status has changed.
      } while (paymentStatus.status === 'initialized');
      return paymentStatus;
    },

    /**
     * @param {Number} timeout
     * @returns {Number}
     */
    increaseTimeout(timeout) {
      return Math.min(TIMEOUT_MAX_LENGTH, timeout * TIMEOUT_INCREMENT_RATE);
    },

    /**
     * @param {Number} waitTime - In ms.
     * @returns {Promise}
     */
    wait(waitTime) {
      return new Promise((resolve) => setTimeout(resolve, waitTime));
    },

    /**
     * Remove the query string and cleans the url to prevent CRLF characters.
     *
     * @see https://www.netsparker.com/blog/web-security/crlf-http-header/
     *
     * @param {String} url
     * @returns {String}
     */
    removeQueryString(url) {
      const urlSpec = new URL(url);
      return urlSpec.href.replace(urlSpec.search, '');
    },

    /**
     * Adds a query param to the href.
     *
     * @param {String} href
     * @param {String} key
     * @param {String} value
     * @returns {String}
     */
    appendQueryParam(href, key, value) {
      const url = new URL(href);
      url.searchParams.append(key, value);
      return url.href;
    },

    /**
     * Returns the absolute return url.
     *
     * @param paymentStatus
     * @returns {String}
     */
    getReturnUrl({paid, payment_initiation_url: referrer}) {
      if (referrer.startsWith('/')) {
        // Make relative url absolute
        return new URL(referrer, window.location.origin).href;
      }

      let href = this.removeQueryString(referrer);

      /*
       * prevent a redirect loop with the payment page and the payment provider
       * This will happen when clicking links from downloaded pdf files
       */
      if (href.includes('payment')) {
        href = href.replace(/payment\/.*/, 'invoices');
      }

      return this.appendQueryParam(href, 'paid', paid);
    },

    /**
     * This function validates the following:
     * - protocol, only http and https
     * - hostname, only alphanumeric characters hyphens and dots
     * - main domain, only our domains.
     *
     * @see https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html
     * @param {String} url
     * @returns {Boolean}
     */
    validateUrl(url) {
      let urlSpec;

      // Return false when the given URL is not valid.
      try {
        urlSpec = new URL(url);
      } catch (e) {
        return false;
      }

      // Only support http and https protocols
      const supportedProtocols = ['http:', 'https:'];

      if (!supportedProtocols.includes(urlSpec.protocol)) {
        return false;
      }

      // Only a-z, hyphens and dots are allowed.
      if (!(/^[a-z.\d-]+$/).test(urlSpec.hostname)) {
        return false;
      }

      // Only the following domains are valid
      const validMainDomains = [
        DOMAIN_FLESPAKKET,
        DOMAIN_MYPARCEL,
        DOMAIN_SENDMYPARCEL,
        DOMAIN_PORTAL_FLESPAKKET,
        DOMAIN_PORTAL_MYPARCEL,
        DOMAIN_PORTAL_SENDMYPARCEL,
      ];

      // On dev, allow the current hostname as well (ignoring subdomain and environment).
      if (isDev) {
        const subdomainPart = subdomain ? `${subdomain}.` : '';
        const environmentPart = environment ? `${environment}.` : '';
        const baseHost = window.location.hostname.replace(subdomainPart + environmentPart, '');

        validMainDomains.push(baseHost);
      }

      const mainDomain = urlSpec.hostname.split('.').slice(-2).join('.');

      return validMainDomains.includes(mainDomain);
    },
  },
};
</script>
